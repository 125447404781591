import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import {logout} from "../actions/auth";
import _ from "lodash";
import keycloak from "../keycloak";
import {getJwtLanguage} from "../utils/parseJWT";

// const networkInterface = createNetworkInterface({
//   uri: process.env.REACT_APP_GRAPHQL_URI || '/graphql',
//   opts: {
//     credentials: 'same-origin',
//   },
// });


export default (token, language, dispatch, i18n) => {
    let cache = new InMemoryCache({
        possibleTypes: {
            ServiceTicketMaintenance: []
        }
    });

    const vsmEndpoint = createHttpLink({uri: process.env.REACT_APP_GRAPHQL_URI || `/graphql${window.__DEV__ ? '?' : ''}`});
    const federatedGraphEndpoint = createHttpLink({uri: process.env.FEDERATED_GRAPHQL_URI || '/graphql-federated'});
    // const federatedGraphEndpoint = createHttpLink({uri: 'https://digital-twin-testing.dreebit.com/graphql-federated'});

    const lang = getJwtLanguage(keycloak.token);

    const middlewareLink = setContext((_, { headers, ...context }) => {
        let token = keycloak.token || undefined

        //seperate handling for workflowboards because of device authentication
        const workflowboardToken = localStorage.getItem('workflowboard_token');
        if (context?.application === "workflowboard" && workflowboardToken) {
            token = workflowboardToken
        }

        return {
            headers: {
                authorization: token ? `Bearer ${token}` : null,
                "Accept-Language": keycloak.token ? `${lang}` : "en",
            }
        }
    });

    const errorLink = onError((args) => {
        const {networkError, graphQLErrors, response, operation} = args;


        if ((networkError && networkError.statusCode === 401) || (graphQLErrors && graphQLErrors.length && _.first(graphQLErrors).status === 401)) {
            //keycloak.logout()
        }else if (response && response.errors) {
            response.errors = response.errors.map((err) => {
                return {
                    ...err,
                    operation
                }
            })
        }
    });


    const vsmLink = middlewareLink.concat(errorLink).concat(vsmEndpoint);
    const federatedGraphLink = middlewareLink.concat(errorLink).concat(federatedGraphEndpoint);

    const client = new ApolloClient({
        link: ApolloLink.split(
            operation => operation.getContext().clientName === 'federatedGraphEndpoint',
            federatedGraphLink, //if above
            vsmLink
        ),
        cache,
        defaultOptions: {
            query: {
                fetchPolicy: 'cache-first'
            }
        }
    });

    return client;
};
