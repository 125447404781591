// @flow
import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import ProfileDeviceConnections from "../../../components/ProfileDeviceConnections";
import ProfileDeviceConnectionCreate from "../../../components/ProfileDeviceConnectionCreate";
import ProfileDeviceConnectionDetail from "../../../components/ProfileDeviceConnectionDetail";
import withResources from "../../../hoc/withResources";


class DeviceConnectionsRoute extends Component<Props, State> {

    render() {


        return <Switch>
            <Route exact path="/profile/deviceConnections" component={ProfileDeviceConnections}/>
            {/*<Route exact path="/profile/deviceConnections/create" component={ProfileDeviceConnectionCreate}/>*/}
            <Route exact path="/profile/deviceConnections/:id" component={ProfileDeviceConnectionDetail}/>
        </Switch>;
    }
}

export default withResources('administrateConnectors')(DeviceConnectionsRoute);
