import React, {useState} from "react";
import {useQuery} from "@apollo/client";
import BookmarkQuery from "../../graphql/queries/bookmarkData.graphql"
import VSMLoadingAnimation from "../VSMLoadingAnimation";
import _ from "lodash";
import {Result} from "antd";
import {useTranslation} from "react-i18next";
import ServiceTicketWorkflowBoardResults from "../ServiceTicketWorkflowBoard/results";
import parseSearchQueryParams from "../../utils/searchParams";
import NavigationContainer from "../NavigationContainer";
import {FlexCol, FlexGrow, FlexRow} from "@dreebit/react-components";
import Clock from "../Clock";
import './index.css';
import moment from "moment";

interface Props {
    bookmarkData: string,
    className?: string,
    isExternal?: boolean,
    title?: string,
}

const ServiceTicketBookmarkWorkflowBoard = ({bookmarkData, className, isExternal, title}: Props) => {


    const {t} = useTranslation();
    const [lastUpdate, setLastUpdate] = useState("-");

    const errorView = <Result
        status="500"
        title={t("No workflow filter found")}
        subTitle={<div>
            <div>{t("Please use a different bookmark")}:</div>
        </div>}
    />;

    let filter = null;
    let showError = !bookmarkData;

    try {
        const filterJson = JSON.parse(bookmarkData);
        const link = _.get(filterJson,'link');
        filter = parseSearchQueryParams(_.last(link.split("?")))

    } catch {
        showError = true;
    }


    if ((showError || !filter) && process.env.NODE_ENV !== 'development') {
        return errorView
    }

    return <NavigationContainer
      navigationBar={
          <FlexRow className={"flex-center bb flex-align-items-center p-5"}>
            <FlexGrow className={"text-center"}>
                <h3>{title || t("Workflowboard")}</h3>
            </FlexGrow>
            <FlexCol className={"pr-10"} style={{marginLeft: -210}}>
                <div className={'flex-self-end text-bold'}>
                    <Clock/>
                </div>
                <div className={'small-text'}>
                    {t('Last update')}: {lastUpdate}
                </div>
            </FlexCol>
          </FlexRow>}
    >
        <ServiceTicketWorkflowBoardResults
            className={className}
            pollInterval={1000}
            filter={filter}
            afterFetch={() => setLastUpdate(moment(Date.now()).calendar())}
            isExternal={isExternal}
        />
    </NavigationContainer>
}

export default ServiceTicketBookmarkWorkflowBoard;
