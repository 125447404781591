// @flow
import React from "react"
import {useTranslation} from "react-i18next";
import Panel from "../Panel";
import _ from "lodash";
import DeviceConnectionQuery from "../../graphql/queries/deviceConnection.graphql";
import UpdateDeviceConnectionMutation from "../../graphql/mutations/updateDeviceConnection.graphql";
import AttributesInlineForm from "../Form/attributes-inline-form";
import {useMutation, useQuery} from "@apollo/client";

interface Props {
    id: string
}

const DeviceConnectionDashboard = ({id}: Props) => {
    const {t} = useTranslation();

    const deviceConnectionQuery = useQuery(DeviceConnectionQuery, {
        variables: {
            deviceConnectionId: id
        }
    })
    const [updateDeviceConnectionMutation] = useMutation(UpdateDeviceConnectionMutation, {
        refetchQueries: [
            {
                query: DeviceConnectionQuery,
                variables: {
                    id: id
                }
            }
        ]
    })

    const typeDefintionAttributes = _.get(deviceConnectionQuery, 'data.deviceConnection.typeDefinitionAttributeList.typeDefinitionAttributes', [])

    return (
        <div>
            <Panel title={t('Edit')} style={{margin: 14}}>
                <AttributesInlineForm
                    attributes={typeDefintionAttributes}
                    layout={'horizontal'}
                    useSubmit={true}
                    t={t}
                    mutation={updateDeviceConnectionMutation}
                    translateMutationOptions={({values}) => ({
                        variables: {
                            deviceConnectionId: id,
                            input: values
                        }
                    })}
                />
            </Panel>
        </div>
    );
}

export default DeviceConnectionDashboard;
