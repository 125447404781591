// @flow
import React, {Component} from "react"
import _ from "lodash";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import {userIsAuthenticatedRedir} from "../../utils/auth";
import {Col, Menu, Row} from "antd";
import {Button} from "@dreebit/react-components";
import MenuClass from "./../../utils/MenuClass";
import {connect} from "react-redux";
import MenuFactory from "../../utils/menu";

import ProfileDashboard from "./../../components/ProfileDashboard";
import ProfileIntro from "../../components/ProfileIntro";
import ProfileSessions from "../../components/ProfileSessions";
import DeviceConnectionsRoute from "./deviceConnections";
import ProfileNotificationSettingsDetail from "../../components/ProfileNotificationSettingsDetail";
import ProfileNotificationSettingsCreate from "../../components/ProfileNotificationSettingsCreate";
import ProfileNotificationSettings from "../../components/ProfileNotificationSettings";
import NavigationContainer from "../../components/NavigationContainer";
import SystemRoute from "./system";
import withProfile from "../../hoc/withProfile";

type Props = {
    resources: any[],
    location: any,
    t: Function,
}

class ProfileRoute extends Component<Props> {

    render() {
        const {t, resources, match} = this.props;

        let vsmMenu = new MenuClass("profile", [], false, t("Profile"), 0, null);
        vsmMenu.addMenuItem(new MenuClass("details", [], false, t("Details"), 0, null, "/profile/details"));
        vsmMenu.addMenuItem(new MenuClass("system", [], false, t("System Info"), 0, null, "/profile/system"));
        if(resources.indexOf('administrateConnectors') > -1) vsmMenu.addMenuItem(new MenuClass("deviceConnections", [], false, t("Device-Connections"), 0, null, "/profile/deviceConnections"));
        vsmMenu.addMenuItem(new MenuClass("notificationsettings", [], false, t("Notificationsettings"), 0, null, "/profile/notificationsettings"));


        const openKeys = vsmMenu.items.map(item => item.id);

        return <Row className="full-size">
            <Col className="full-height br" sm={10} md={8} lg={6} xl={5} xxl={4}>

                <NavigationContainer
                    navigationBar={
                        <div>
                        <div className={"white-bg bb pv-20"}>
                            <ProfileIntro/>
                            <div className={"text-center"}>
                                <Link to={'/logout'}><Button className={'LogoutButton'} ghost
                                                             type={'danger'}>{t('Logout')}</Button></Link>
                            </div>
                        </div>
                        <div className={"bg-light-grey pv-10"}/>
                        </div>
                    }
                >
                    <div className={"bg-white bt full-height overflow-scroll-y"}>
                        <Menu
                            className="no-border vertical-menu vertical-menu-lg"
                            mode="inline"
                            inlineIndent={8}
                            selectedKeys={[_.get(match,'params.index',"details")]}
                            openKeys={openKeys}
                        >
                            {MenuFactory.getMenuItems(vsmMenu.items, t, false)}
                        </Menu>
                    </div>

                </NavigationContainer>


            </Col>
            <Col className="full-height" sm={14} md={16} lg={18} xl={19} xxl={20}>
                <Switch>
                    <Route exact path="/profile/details" component={ProfileDashboard}/>
                    <Route exact path="/profile/sessions" component={ProfileSessions}/>
                    <Route path="/profile/deviceConnections" component={DeviceConnectionsRoute}/>
                    <Route path="/profile/system" component={SystemRoute}/>
                    <Route exact path="/profile/notificationsettings/create"
                           component={ProfileNotificationSettingsCreate}/>
                    <Route exact path="/profile/notificationsettings/:id"
                           component={ProfileNotificationSettingsDetail}/>
                    <Route exact path="/profile/notificationsettings" component={ProfileNotificationSettings}/>

                    <Redirect exact from="/profile" to="/profile/details"/>
                </Switch>

            </Col>
        </Row>
    }

}

const getUserResourcesArray = (userData) => {
    let resources = [];

    if (userData && userData.roles)
        userData.roles.forEach((role) => {
            if (role.resources) {
                role.resources.forEach((resource) => {
                    resources.push(resource['key']);
                })
            }
        });

    return resources;
};

export default compose(
    userIsAuthenticatedRedir,
    connect(state => ({
        resources: getUserResourcesArray(state.auth.user),
    })),
    withTranslation(),
    withProfile()
)(ProfileRoute);
