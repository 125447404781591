// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";

import ProfileQuery from "./../../graphql/queries/profileSessions.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ContentHeader from "../Typo/ContentHeader";
import ContentWrapper from "../ContentWrapper";
import DeviceConnectionsListTable from "../DeviceConnectionsListTable";
import {Link} from "react-router-dom";
import {Button} from "@dreebit/react-components";
import Panel from "../Panel";


class ProfileDeviceConnections extends Component<Props, State> {

    render() {
        const {t} = this.props;

        return <div>
            <ContentHeader
                title={t('Connectors')}
                // tools={
                //     <div>
                //         <Link to={'/profile/connectors/create'}>
                //             <Button icon={"plus"}>
                //                {t('Add')}
                //             </Button>
                //         </Link>
                //     </div>
                // }
            />
            <ContentWrapper>
                <Panel containerStyle={{padding: 0}}>
                    <DeviceConnectionsListTable
                        onClick={(deviceConnection) => this.props.history.push(`/profile/deviceConnections/${deviceConnection.id}`)}
                    />
                </Panel>
            </ContentWrapper>
        </div>;
    }
}

export default compose(
    withTranslation(),
    graphql(ProfileQuery, {
        name: 'ProfileQuery',
    }),
    waitWhileLoading('ProfileQuery'),
    withProps((props) => ({
        query: _.get(props, 'ProfileQuery'),
    }))
)(ProfileDeviceConnections);
