import * as actionTypes from './actionTypes';
import uuid from "../utils/uuid";
import {GLOABL_PERSISTOR} from "../routes/root";

export const setUser = (user) => dispatch => {
    dispatch({
        type: actionTypes.SET_USER,
        user,
    });
};


export const setToken = (token) => dispatch => {
    dispatch({
        type: actionTypes.SET_TOKEN,
        token,
    });
};

export const setWorkflowboardToken = (tokenData, uuid) => dispatch => {
    dispatch({
        type: actionTypes.SET_WORKFLOWBOARD_TOKENDATA,
        tokenData,
    });
};

export const setWorkflowboardDeviceId = (deviceId) => dispatch => {
    dispatch({
        type: actionTypes.SET_WORKFLOWBOARD_DEVICEID,
        deviceId,
    });
};

export const setWorkflowboardUuid = (uuid) => dispatch => {
    dispatch({
        type: actionTypes.SET_WORKFLOWBOARD_UUID,
        uuid,
    });
};

export const login = (user, token) => dispatch => {
    dispatch({
        type: actionTypes.USER_LOGGED_IN,
        user,
        token,
    });

    setTimeout(() => {
        if (GLOABL_PERSISTOR){
            GLOABL_PERSISTOR.flush().then(() => {
                console.log("GLOABL_PERSISTOR flushed state")
            });
        }
    })
};

export function logout() {
    return {
        type: actionTypes.USER_LOGGED_OUT,
    };
}

export function userUUID() {
    return {
        type: actionTypes.USER_UUID,
        uuid: uuid()
    };
}
