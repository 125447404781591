import _ from "lodash";
import { useSelector } from 'react-redux'


export function useWorkflowboardTokenData() {
    return useSelector((state) => {
        return _.get(state, 'auth.workflowboard_tokenData')
    })
}

export function useWorkflowboardUuid() {
    return useSelector((state) => {
        return _.get(state, 'auth.workflowboard_uuid')
    })
}

export function useWorkflowboardDeviceId() {
    return useSelector((state) => {
        return _.get(state, 'auth.workflowboard_deviceId')
    })
}
