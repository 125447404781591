// antd version of the hub button because @dreebit-registry/vsm-microservice-components uses mui/joy
import React from 'react';
import {Button, Popover} from "antd";
import "./index.css";
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";
import useResources from "../../hooks/useResources";
import {checkResource} from "../../hoc/withResources";
import {useTranslation} from "react-i18next";
import MicroserviceAppLink from "../MicroserviceAppLink";
import SvgMSAnalyticsIcon from "../../svg-graphics/SvgMSAnalyticsIcon";
import SvgMSInstalledBaseIcon from "../../svg-graphics/SvgMSInstalledBaseIcon";
import SvgMSCatalogIcon from "../../svg-graphics/SvgMSCatalogIcon";
import SvgMSOttoIcon from "../../svg-graphics/SvgMSOttoIcon";

interface Props {
    title: string,
    subtitle?: string,
    keycloakToken?: string,
    resources: string[]
}

const MicroserviceAppHub = ({keycloakToken, title, subtitle, resources}: Props) => {

    const {t} = useTranslation();

    const [popoverOpen, setPopoverOpen] = React.useState(false);
    const handleOpenChange = (newOpen: boolean) => {
        setPopoverOpen(newOpen);
    };

    const baseUrl = process.env.NODE_ENV !== 'production' ? "https://testing-vsm.pfeiffer-vacuum.com" : window.location.origin;

    const appLinkConfig = [
        {
            title: t("Installed Base"),
            icon: <SvgMSInstalledBaseIcon color={"#fff"} />,
            link: baseUrl + '/#/'
        },
        {
            title: t("Catalog"),
            icon: <SvgMSCatalogIcon height={24}/>,
            style: {paddingTop: 8},
            link: baseUrl + '/catalog/',
            resource: 'catalogEditor'
        },
        {
            title: t("Analytics"),
            icon: <SvgMSAnalyticsIcon height={24}/>,
            style: {paddingTop: 8},
            link: baseUrl + '/analytics/',
            resource: 'analytics'
        },
        {
            title: t("OTTO Dashboard"),
            icon: <SvgMSOttoIcon height={24} />,
            style: {paddingTop: 8},
            comingSoon: true,
            resource: 'ottoDashboard'
        }
    ]

    const appLinksFilteredByResource = appLinkConfig.filter(link => {
        if (link.resource ) {
            return resources.includes(link.resource);
        } else {
            return true
        }
    })

    const appLinks = appLinksFilteredByResource.filter(link => {
        return !link.comingSoon;
    })
    const appLinksComingSoon = appLinksFilteredByResource.filter(link => {
        return link.comingSoon;
    })

    const appLinksContent = appLinks.map((link, index) => (
        <MicroserviceAppLink key={index} title={link.title} icon={link.icon} style={link.style} link={link.link}/>
    ));

    const appLinksComingSoonContent = appLinksComingSoon.map((link, index) => (
        <MicroserviceAppLink key={index} title={link.title} icon={link.icon} style={link.style} disabled={true} />
    ));

    const popoverContent = <div className={"popover-content-wrapper"}>
        <div className={"popover-user-info"}>
            <span className={"popover-user-info-title"}>
                {title}
            </span>
            <span className={"popover-user-info-subtitle"}>
                {subtitle}
            </span>
        </div>
        <span className={"popover-headline"}>
            {t("Your Apps")}
        </span>
        <div>
            {appLinksContent}
        </div>
        {
            appLinksComingSoon.length > 0 ?
                <div>
                    <span className={"popover-headline"}>
                        {t("Coming soon")}
                    </span>
                    <div>
                        {appLinksComingSoonContent}
                    </div>
                </div>
            : null
        }

    </div>

    if (!keycloakToken) {
        return null;
    }

    if (appLinks.length + appLinksComingSoon.length <= 1) {
        return null;
    }

    return (
        <Popover
            placement="bottomLeft"
            showArrow={false}
            content={popoverContent}
            trigger="click"
            open={popoverOpen}
            onOpenChange={handleOpenChange}
        >
            <Button className={"microservice-menu-button"}>
                <MaterialCommunityIcon name={"apps"} size={24}/>
            </Button>
        </Popover>
    );
}

export default MicroserviceAppHub;
