// @flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {message} from "antd";
import {Button} from "@dreebit/react-components";
import _ from "lodash";
import ContentHeader from "../../components/Typo/ContentHeader";
import DeviceConnectionDashboard from "../../components/DeviceConnectionDashboard";
import DeviceConnectionQuery from "../../graphql/queries/deviceConnection.graphql";
import DeleteDeviceConnectionMutation from "../../graphql/mutations/deleteDeviceConnection.graphql";
import {useHistory, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import DeviceConnectionList from "../../graphql/queries/deviceConnectionList.graphql";


const ProfileDeviceConnectionDetail = () => {
    const {id} = useParams();
    const history = useHistory();
    const {t} = useTranslation();

    const deviceConnectionQuery = useQuery(DeviceConnectionQuery, {
        variables: {
            deviceConnectionId: id
        }
    });
    const [deleteDeviceConnectionMutation] = useMutation(DeleteDeviceConnectionMutation, {
        refetchQueries: [
            {
                query: DeviceConnectionList
            }
        ]
    });

    const handleDelete = () => {
        deleteDeviceConnectionMutation({
            variables: {
                deviceConnectionId: id
            }
        }).then((res) => {
            message.success(_.get(res, 'data.deleteDeviceConnection.message', ""));
            history.push('/profile/deviceConnections');
        })
    };

    const title = _.get(deviceConnectionQuery, 'data.deviceConnection.name', t('No title'));

    return <div>
        <ContentHeader
            title={title}
            tools={
                <Button
                    type={'danger'}
                    onClick={handleDelete}
                >
                    {t('Delete')}
                </Button>
            }
        />
        <DeviceConnectionDashboard id={id}/>
    </div>
}

export default ProfileDeviceConnectionDetail;
