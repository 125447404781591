// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Col, Row} from 'antd';

import ContentHeader from "../../components/Typo/ContentHeader";
import ConnectorCreateForm from ".//form";
import LookupsListTable from "../LookupsListTable";
import Panel from "../Panel";
import _ from "lodash";

type Props = {
    history: {
        push: Function
    },
    t: Function,
}

class ProfileDeviceConnectionCreate extends Component<Props> {
    render() {
        const {t, history} = this.props;
        return <div style={{width: '100%'}}>
            <div style={{width: '100%'}}>
                <ContentHeader title={t('Add new Connector')}/>
            </div>
            <Row style={{width: '100%'}}>
                <Col span={8}>
                    <div style={{margin: '10px'}}>
                        <Panel title={t('Edit')}>
                            <ConnectorCreateForm onSaved={(res) => {
                                const id = _.get(res, 'data.createAccessToken.id')
                                if (id) history.push(`/profile/connectors/${id}`)
                            }}/>
                        </Panel>
                    </div>
                </Col>
                <Col span={16}>
                    <div style={{margin: '10px'}}>
                        <Panel title={t('Lookups')}>
                            <LookupsListTable/>
                        </Panel>
                    </div>
                </Col>
            </Row>
        </div>;
    }
}

export default withTranslation()(withRouter(ProfileDeviceConnectionCreate));
