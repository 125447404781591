// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import DeviceConnectionQuery from "../../graphql/queries/deviceConnection.graphql";

const DeviceConnectionBreadcrumb = ({deviceConnectionQuery, t}) =>
    <span>{_.get(deviceConnectionQuery, 'deviceConnection.name', t('No title'))}</span>;

export default compose(
    graphql(DeviceConnectionQuery, {
        name: 'deviceConnectionQuery',
        options: ({match}) => ({
            variables: {
                deviceConnectionId: _.get(match, 'params.id')
            }
        }),
    }),
)(withTranslation()(DeviceConnectionBreadcrumb));
