// @flow
import React, {Component} from 'react';
import WorkflowBoard from "../WorkflowBoard/board";
import {graphql, withApollo} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import UpdateServiceTicketRankMutation from "../../graphql/mutations/updateServiceTicketRank.graphql";
import ServiceTicketWorkFlowListItemQuery from "../../graphql/queries/ServiceTicketWorkFlowListItem.graphql";
import ServiceTicketListQuery from "../../graphql/queries/ServiceTicketWorkFlowList.graphql";
import CreateServiceActionMutation from "./../../graphql/mutations/createServiceAction.graphql";
import {toastError, toastMessage} from "../../utils/toast";
import Strings from "../../i18n/strings";
import ServiceTicketWorkFlowListItem from "./listItem";
import {Modal} from "antd";

import ServiceWorkflowDoneActionSteps from "../ServiceWorkflowDoneActionSteps";
import {sortDirtyList} from "./utils";

type Props = {
    filter: any,
    pollInterval?: number,
    className?: string,
    afterFetch?: () => void
};

type State = {
    modalVisible: boolean,
    dirtyRanks: { id: string, index: number, column: string, oldColumn?: string }[]
}


const createServiceActionVariablesConfig = {
    "inProgress": (serviceTicketId) => ({
        serviceActionTypeIndex: "componentInWork",
        serviceTicketId: serviceTicketId,
        input: {}
    }),
    "done": (serviceTicketId) => ({
        serviceActionTypeIndex: "componentWorkCompleted",
        serviceTicketId: serviceTicketId,
        input: {}
    }),
    "open": (serviceTicketId, serviceTicket) => {
        return {
            serviceActionTypeIndex: "serviceTicketAssignedToServiceDepartment",
            serviceTicketId: serviceTicketId,
            input: {
                serviceDepartmentId: _.get(serviceTicket, 'serviceDepartment.id')
            }
        }
    },
};

export const colConfig = [{
    id: 'open',
    title: 'Open state',
    queryName: 'openServiceTicketList'
}, {
    id: 'inProgress',
    title: 'In Progress',
    queryName: 'inProgressServiceTicketList'
}, {
    id: 'done',
    title: 'Done',
    queryName: 'doneServiceTicketList'
}]


class ServiceTicketWorkflowBoardResults extends Component<Props, State> {


    state = {
        modalVisible: false,
        doneIds: [],
        dirtyRanks: []
    };

    workflowBoardRef = React.createRef();

    _handleAskForMoveDepartment = (ids) => {
        this.setModalVisible(true, ids);
    };

    _handleModalOK = () => {
        this.setModalVisible(false).then(() => {
            this.props.doneServiceTicketList.refetch().then(() => {
                this.clearDirty();
            })
        })
    };

    _handleModalClose = () => {
        this.setModalVisible(false)
    };

    clearDirty() {

        const newCols = Object.keys(_.groupBy(this.state.dirtyRanks, 'column'));
        const oldCols = Object.keys(_.groupBy(this.state.dirtyRanks, 'oldColumn'));
        const cols = _.uniq([...oldCols, ...newCols])

        return Promise.all(cols.map((col) => {
            const colConf = colConfig.find((item) => item.id === col);
            if (!colConf || !colConf.queryName) return Promise.resolve();
            return this.props[colConf.queryName].refetch();
        })).then(() => {

            return new Promise((resolve) => {
                setTimeout(() => {
                    console.log('clear dirty')
                    this.setState({
                        dirtyRanks: [],
                    }, () => {
                        resolve();
                    })
                }, 100)
            })
        })

    }

    setModalVisible = (modalVisible, doneIds) => {
        return new Promise((resolve) => {
            this.setState({
                doneIds,
                modalVisible
            }, resolve)
        })
    };

    filterItemsForColumn(column) {
        const items = _.uniqBy(sortDirtyList([
            ..._.get(this.props, 'openServiceTicketList.serviceTicketList.serviceTickets', []),
            ..._.get(this.props, 'inProgressServiceTicketList.serviceTicketList.serviceTickets', []),
            ..._.get(this.props, 'doneServiceTicketList.serviceTicketList.serviceTickets', []),
        ], this.state.dirtyRanks), 'id')

        const dirtyMap = this.state.dirtyRanks.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
        }, {})

        return items.filter((item) => {
            let col = _.get(item, 'serviceDepartmentState.index');
            if (dirtyMap[item.id] && dirtyMap[item.id].column) {
                col = dirtyMap[item.id].column
            }
            return column === col
        })
    }

    render() {
        const {updateServiceTicketRankMutation, createServiceActionMutation, t, className} = this.props;


        const columns = colConfig.map((item) => {
            return {
                ...item,
                title: t(item.title),
                queryName: item.queryName,
                itemsKeyPath: 'serviceTicketList.serviceTickets',
                totalKeyPath: 'serviceTicketList.total',
                filter: this.props.filter,
                loading: _.get(this.props, item.queryName + '.loading', false) || this.state.dirtyRanks.filter((di) => di.column === item.id).length > 0,
                // hide list while editing multiple
            }
        })

        return <div className={`full-size ${className}`}>
            <Modal
                footer={null}
                visible={this.state.modalVisible}
                onOk={this._handleModalOK}
                onCancel={this._handleModalClose}
                width={800}
            >
                <ServiceWorkflowDoneActionSteps
                    serviceTicketIds={this.state.doneIds}
                    onDone={() => {
                        this._handleModalOK();
                    }}
                />
            </Modal>
            <WorkflowBoard
                ref={this.workflowBoardRef}
                renderItem={(item) => {
                    return <ServiceTicketWorkFlowListItem item={item}/>
                }}
                onChange={(ids, index, oldColumnId, newColumnId) => {

                    const dirtyIds = [...this.state.dirtyRanks, ...ids.map((id, itemIndex) => {
                        return {
                            id,
                            index: index + itemIndex,
                            column: newColumnId,
                            oldColumn: oldColumnId
                        }
                    })];

                    return new Promise((resolve, reject) => {

                        this.setState({
                            dirtyRanks: _.uniqBy(dirtyIds, 'id')
                        }, () => {
                            resolve()
                        })
                    }).then(() => {

                        if (oldColumnId === newColumnId) return Promise.resolve()

                        const promises = ids.map(id => {

                            if (newColumnId === 'open') {
                                return this.props.client.query({
                                    query: ServiceTicketWorkFlowListItemQuery,
                                    variables: {
                                        id
                                    }
                                }).then(({data}) => {

                                    if (!_.get(data, 'serviceTicket.serviceDepartment.id')) {
                                        throw new Error(t("Service ticket has no department"))
                                    }
                                    return createServiceActionMutation({
                                        variables: createServiceActionVariablesConfig[newColumnId](id, _.get(data, 'serviceTicket')),
                                    })
                                })
                            }
                            return createServiceActionMutation({
                                variables: createServiceActionVariablesConfig[newColumnId](id),
                            })
                        });

                        return Promise.all(promises)
                            .then(() => {
                                toastMessage(t(Strings.success))
                            })
                            .then(() => {
                                if (newColumnId === 'done') {
                                    this._handleAskForMoveDepartment(ids)
                                }
                            })
                            .catch((err) => {
                                toastError(err)
                            })

                    }).then(() => {

                        const list = this.filterItemsForColumn(newColumnId).filter((item) => ids.indexOf(item.id) === -1)
                        let insertIndex = index - ids.length;
                        let beforeItem = null;
                        let afterItem = null;
                        if (insertIndex > 0) {
                            beforeItem = list[insertIndex] || list[index]
                        } else {
                            afterItem = list[index]
                        }

                        return updateServiceTicketRankMutation({
                            variables: {
                                ids: ids.reverse(),
                                beforeId: _.get(beforeItem, 'id'),
                                afterId: _.get(afterItem, 'id'),
                            }
                        })

                    }).then(() => {
                        return this.clearDirty()
                    })
                        .catch(toastError)


                }}
                columns={columns}
            />
        </div>
    }
}

export const getVariablesForFilter = (serviceTicketStateIndex, filter) => {
    if (!filter) return {
        params: {
            start: 0,
            limit: 50,
            filter: {
                serviceDepartmentStateIndex: {
                    value: serviceTicketStateIndex
                }
            }
        }
    }

    const filterValues = {};
    Object.keys(filter).forEach((key) => {
        filterValues[key] = {
            value: filter[key] === "true" || filter[key] === "false" ? filter[key] === "true" : filter[key]
        }
    });

    return {
        params: {
            start: 0,
            limit: 50,
            filter: {
                serviceDepartmentStateIndex: {
                    value: serviceTicketStateIndex
                },
                ...filterValues
            }
        }
    }

};

export default compose(
    withTranslation(),
    graphql(UpdateServiceTicketRankMutation, {
        name: 'updateServiceTicketRankMutation',
    }),
    graphql(CreateServiceActionMutation, {
        name: 'createServiceActionMutation',
    }),
    graphql(ServiceTicketListQuery, {
        name: 'openServiceTicketList',
        options: (props: Props) => ({
            pollInterval: props.pollInterval,
            variables: getVariablesForFilter('open', props.filter),
            context: {
                application: 'workflowboard',
            }
        }),
    }),
    graphql(ServiceTicketListQuery, {
        name: 'inProgressServiceTicketList',
        options: (props: Props) => ({
            pollInterval: props.pollInterval,
            variables: getVariablesForFilter('inProgress', props.filter),
            context: {
                application: 'workflowboard',
            }
        }),
    }),
    graphql(ServiceTicketListQuery, {
        name: 'doneServiceTicketList',
        options: (props: Props) => ({
            pollInterval: props.pollInterval,
            variables: getVariablesForFilter('done', props.filter),
            onCompleted: props.afterFetch ? props.afterFetch() : null,
            context: {
                application: 'workflowboard',
            }
        }),
    }),
    withApollo
)(ServiceTicketWorkflowBoardResults);
