import React, {useEffect, useState} from 'react';
import uuid from "../../utils/uuid";
import {useTranslation} from "react-i18next";
import {fetchDeviceGrantCode, pollForToken} from "../../utils/fetchDeviceGrantCode";
import Loader from "../Loader";
import Panel from "../Panel";
import Logo from "../Logo";
import {QRCodeSVG} from "qrcode.react";
import {useDispatch} from "react-redux";
import {
    useWorkflowboardDeviceId,
    useWorkflowboardTokenData,
    useWorkflowboardUuid
} from "../../hooks/useWorkflowboardToken";
import {setWorkflowboardDeviceId, setWorkflowboardToken, setWorkflowboardUuid} from "../../actions/auth";
import AuthTokenWorkflowBoard from "../AuthTokenWorkflowBoard";
import {useMutation} from "@apollo/client";
import CreateDeviceConnectionMutation from "../../graphql/mutations/createDeviceConnection.graphql";
import {message} from "antd";
import useWorkflowboardData from "../../hooks/useWorkflowboardData";
import moment from "moment/moment";

interface Props {

}

const WorkflowboardRegistration = ({}: Props) => {

    const dispatch = useDispatch()
    const tokenData = useWorkflowboardTokenData();
    const workflowboardUuid = useWorkflowboardUuid();
    const deviceId = useWorkflowboardDeviceId();
    const authToken = localStorage.getItem('workflowboard_token')
    // state for device code --> QR Code link, user device code for registration, ...
    const [deviceCodeData, setDeviceCodeData] = useState(null);
    // const boardData = useWorkflowboardData();
    const {t} = useTranslation();
    const [createDeviceConnectionMutation] = useMutation(CreateDeviceConnectionMutation, {})

    useEffect(() => {
        async function getDeviceCode() {
            const data = await fetchDeviceGrantCode();
            setDeviceCodeData(data);

            pollForToken(data.device_code, 5000)
                .then((tokenData) => {
                    if (tokenData.access_token) {
                        console.log("Erfolgreich angemeldet:", tokenData);
                        const workflowboardId = uuid();
                        dispatch(setWorkflowboardToken(tokenData))
                        //set extra for apollo
                        localStorage.setItem('workflowboard_token', tokenData?.access_token)
                        createDeviceConnectionMutation({
                            variables: {
                                input: {
                                    connectionType: "workflowboard",
                                    uuid: workflowboardId,
                                    name: `Workflowboard ${workflowboardId}`
                                }
                            },
                            context: {
                                application: 'workflowboard',
                            }
                        })
                            .then((res) => {
                                if (res.data?.createDeviceConnection?.uuid && res.data?.createDeviceConnection?.id) {
                                    dispatch(setWorkflowboardUuid(res.data?.createDeviceConnection?.uuid))
                                    dispatch(setWorkflowboardDeviceId(res.data?.createDeviceConnection?.id))
                                } else {
                                    message.error(t("Error establishing the device connection to the VSM."))
                                }
                            })
                            .catch((err) => {
                                message.error(t("Error establishing the device connection to the VSM."))
                            })
                    }
                });
        }

        if (!tokenData) {
            getDeviceCode();
        }
    }, [tokenData, workflowboardUuid, deviceId]);

    const onLogout = () => {
        dispatch(setWorkflowboardToken(null))
        dispatch(setWorkflowboardUuid(null))
        dispatch(setWorkflowboardDeviceId(null))
        localStorage.removeItem('workflowboard_token')
        keycloak.logout()
    }

    console.log(workflowboardUuid, 'workflowboardUuid')
    console.log(deviceId, 'deviceId')

    if (workflowboardUuid && deviceId) {
        return <AuthTokenWorkflowBoard
            className={'monitorWorkflowBoard'}
            deviceConnectionId={deviceId}
            uuid={workflowboardUuid}
            onLogout={onLogout}
        />
    }

    if (!deviceCodeData) {
        return <Loader />;
    }

    return <div className={"full-size workflowboard"}>
        <div className={"bg-light-grey flex flex-center full-height flex-align-items-center"}>
            <Panel>
                <div className={"flex-col flex-center mh-20 mv-30"}>
                    <div className={"mv-20"} style={{textAlign: 'center'}}>
                        <Logo/>
                        <h3 className={"mt-10"}>{t("Register a workflowboard")}</h3>
                        <div>{t("Use the QR-Code to login")}.</div>
                        <div>
                            Or visit <a href={deviceCodeData.verification_uri} target={"_blank"}>{deviceCodeData.verification_uri}</a> and enter the code:
                        </div>
                    </div>
                    <div style={{width: 200, textAlign: 'center', fontSize: 20, fontWeight: 'bold', border: '1px solid black'}}>
                        {deviceCodeData.user_code}
                    </div>
                    <div className={"flex flex-center mt-20"}>
                        <QRCodeSVG value={deviceCodeData.verification_uri_complete} size={200}/>
                    </div>
                    {/*<AuthTokenLookup/>*/}
                </div>
            </Panel>
        </div>
    </div>
}

export default WorkflowboardRegistration;
