import React, {useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import _ from "lodash";
import DeviceConnectionQuery from "./../../graphql/queries/deviceConnection.graphql"
import VSMLoadingAnimation from "../VSMLoadingAnimation";
import {useTranslation} from "react-i18next";
import {Result} from "antd";
import ServiceTicketBookmarkWorkflowBoard from "../ServiceTicketBookmarkWorkflowBoard";


interface Props {
    className?: string,
    deviceConnectionId: string,
    uuid: string,
    onLogout: () => void,
}

const AuthTokenWorkflowBoard = ({className, deviceConnectionId, uuid, onLogout}: Props) => {

    const {t} = useTranslation();
    const [pollInterval, setPollInterval] = useState(5000)

    const deviceConnectionQuery = useQuery(DeviceConnectionQuery, {
        variables: {
            deviceConnectionId: deviceConnectionId
        },
        pollInterval: pollInterval,
        context: {
            application: 'workflowboard',
        }
    });

    const deviceConnection = _.get(deviceConnectionQuery, 'data.deviceConnection', undefined)
    const bookmarkId = _.get(deviceConnection, 'bookmark.id')
    const bookmarkData = _.get(deviceConnection, 'bookmark.data')
    const deviceTitle = _.get(deviceConnection, 'name')

    const errorView =  <Result
        status="500"
        title={t("No workflow filter found")}
        subTitle={<div>
            <div>{t("Please link the device with a bookmark under")}</div>
            <div>{t("VSM > Profile > Device-Connections")}</div>
            <a href={deviceConnection?.id ? `/#/profile/deviceConnections/${deviceConnection?.id}` : '/#/profile/deviceConnections/'} target={"_blank"}>
                {window.location.origin + (deviceConnection?.id ? `/#/profile/deviceConnections/${deviceConnection?.id}` : '/#/profile/deviceConnections/')}
            </a>
        </div>}
    />

    if (deviceConnectionQuery.loading) {
        return <VSMLoadingAnimation/>
    }

    if (!bookmarkId && !bookmarkData) {
        return errorView;
    }

    if ((!deviceConnection && !deviceConnectionQuery.loading) || deviceConnectionQuery.error){
        onLogout();
    }

    return <ServiceTicketBookmarkWorkflowBoard
        className={className}
        bookmarkData={bookmarkData}
        isExternal={true}
        title={deviceTitle}
    />
}

export default AuthTokenWorkflowBoard;
