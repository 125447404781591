import * as actionTypes from '../actions/actionTypes';



const initialState = {
    user: null,
    token: null,
    workflowboard_tokenData: null,
    workflowboard_deviceId: null,
    workflowboard_uuid: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.user
            };
        case actionTypes.SET_TOKEN:
            return {
                ...state,
                token: action.token
            };
        case actionTypes.USER_LOGGED_IN:
            return {
                ...state,
                user: action.user,
                token: action.token
            };
        case actionTypes.USER_LOGGED_OUT:
            return {
                ...state,
                user: null,
                token: null
            };
        case actionTypes.SET_WORKFLOWBOARD_TOKENDATA:
            return {
                ...state,
                workflowboard_tokenData: action.tokenData,
            };
        case actionTypes.SET_WORKFLOWBOARD_UUID:
            return {
                ...state,
                workflowboard_uuid: action.uuid
            };
        case actionTypes.SET_WORKFLOWBOARD_DEVICEID:
            return {
                ...state,
                workflowboard_deviceId: action.deviceId,
            };
        default:
            return state;
    }
};

export default reducer;
